import { Card, CardBody } from "@nextui-org/react";
import { Carousel, IconButton } from "@material-tailwind/react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import SharedServiceInstance from "@/services/shared-service";
import NextImage from "next/image";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import { GlobalSettings } from "@/configuration/global-settings";
import { TbHeadphonesFilled } from "react-icons/tb";
import { BiSolidLike } from "react-icons/bi";
import Link from "next/link";

export default function Audio({ data }) {
    const [urlAudio, setUrlAudio] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const audioRef = useRef();

    const playAudio = async (id) => {
        let previousId = selectedAudio;
        setSelectedAudio(id);
        if (isPlay && previousId === id) {
            setIsPlay(false);
            audioRef.current.pause();
        } else {
            const audioUrl = data.find((item) => item?.id === id).mediaUrl;
            if (audioUrl) {
                setUrlAudio(audioUrl);
                setIsPlay(true);
                async function increaseView() {
                    try {
                        const res = await fetch(
                            `${GlobalSettings.Settings.Server}/api/v1/audio/episodes/${id}/increase-views`,
                            { method: "POST" }
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
                increaseView();
                audioRef.current.play();
            }
        }
    };

    useEffect(() => {
        if (selectedAudio) {
            async function getAudio() {
                const audioUrl = data.find(
                    (item) => item?.id === selectedAudio
                ).mediaUrl;
                if (audioUrl) {
                    setUrlAudio(audioUrl);
                    setIsPlay(true);
                    audioRef.current.play();
                }
            }

            getAudio();
        }
    }, [selectedAudio]);

    const itemSection = data.map((item, index) => {
        if (index < 6) {
            return (
                <Card
                    className="items-center rounded-[5px] border-0 lg:max-w-[400px] object-cover w-full"
                    key={index}
                >
                    <CardBody className="p-5">
                        <NextImage
                            alt={item.title}
                            width={500}
                            height={500}
                            src={SharedServiceInstance.getOptimizeImage(
                                item.photo,
                                "main-post-view"
                            )}
                            className="rounded-[10px] w-full h-[10rem] object-cover"
                        />
                        <h5 className="text-sm font-bold mt-1 pl-1 line-clamp-2">
                            {item.title}
                        </h5>
                        <div className="flex items-center mt-2 w-full justify-between">
                            <div
                                className="flex w-[30px] h-[30px] rounded-[50%] bg-primary"
                                onClick={() => playAudio(item?.id)}
                            >
                                {isPlay && selectedAudio === item?.id ? (
                                    <FaPause className="text-white m-auto" />
                                ) : (
                                    <FaPlay className="text-white m-auto" />
                                )}
                            </div>
                            {isPlay && selectedAudio === item?.id && (
                                <img src="/icons/isPlay.svg" alt="isPlay" />
                            )}
                            <div className="flex items-center gap-2 justify-end">
                                <BiSolidLike className="text-primary" />
                                <span className="text-xs text-primary">
                                    {item.visit || "0"}
                                </span>
                                <TbHeadphonesFilled className="text-primary" />
                                <span className="text-xs text-primary">
                                    {item.visit || "0"}
                                </span>
                            </div>
                        </div>
                        <audio ref={audioRef} src={urlAudio} />
                    </CardBody>
                </Card>
            );
        }
    });

    return (
        <div className="mt-5">
            <div className="flex justify-start">
                <Link
                    href="/audio"
                    className="whitespace-nowrap text-primary font-bold text-2xl mt-[-22px]"
                >
                    AUDIO
                </Link>
                <div className="flex w-full pb-2 pl-2 h-fit">
                    <p className="h-full w-full border-b-2 border-primary mr-2"></p>
                    <Link href="/audio">
                        <p className="text-md text-primary text-nowrap whitespace-nowrap mt-[-18px] pr-5">
                            Xem thêm
                        </p>
                    </Link>
                </div>
            </div>

            {/* Layout Mobile*/}
            <Carousel
                loop="true"
                autoplay={!isPlay}
                autoplayDelay={5000}
                className="rounded-none overflow-hidden lg:hidden h-full"
                prevArrow={({ handlePrev }) => (
                    <IconButton
                        variant="text"
                        size="md"
                        onClick={handlePrev}
                        className="rounded-full bg-gray-600/40 !absolute top-2/4 left-4 -translate-y-2/4"
                    >
                        <FaAngleLeft className="text-3xl" />
                    </IconButton>
                )}
                nextArrow={({ handleNext }) => (
                    <IconButton
                        variant="text"
                        size="md"
                        onClick={handleNext}
                        className="rounded-full bg-gray-600/40 !absolute top-2/4 !right-4 -translate-y-2/4"
                    >
                        <FaAngleRight className="text-3xl" />
                    </IconButton>
                )}
                navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute bottom-4 left-2/4 z-50 hidden -translate-x-2/4 gap-2">
                        {new Array(length).fill("").map((_, i) => (
                            <span
                                key={i}
                                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                    activeIndex === i
                                        ? "w-8 bg-white"
                                        : "w-4 bg-white/50"
                                }`}
                                onClick={() => setActiveIndex(i)}
                            />
                        ))}
                    </div>
                )}
            >
                {itemSection}
            </Carousel>

            {/* Layout Desktop*/}
            <div className="lg:flex hidden gap-5 mt-5">{itemSection}</div>
        </div>
    );
}
